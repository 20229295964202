import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import "./index.css"

import PDF from "./Denkmaltag_2020.pdf"

export default function StuckbildwerkePage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Aktuelles"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Aktuelles"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Aktuelles</h1>
        <div className="newsPageDiv">
          <div className="newsBox">
            <h2 className="newsH2">Führung Altes Finanzamt</h2>
            <h3 className="newsH3">
              Landshuter Straße 4, 13. Sept. 2020, 10/13/15:30 Uhr
            </h3>
            <h4 className="newsH4">
              (je max. 5 Personen) <br /> Der Treffpunkt wird mit der
              Anmeldebestätigung bekannt gegeben
            </h4>
            <p className="newsText">
              Gegenstand der Führung ist das Alte Finanzamt in der Landshuter
              Straße. Es handelt sich bei dem Gebäudekomplex um zweiteilige
              dreigeschossige Walmdachbauten mit Risaliten von 1913 und einen
              Zwischentrakt von 1962. Durch Zwerchhäuser, Zwerchgiebeln und
              Portale gegliedert, entsprechen die historischen Fassaden ganz dem
              Stil der Neurenaissance.
            </p>
            <p className="newsText">
              Im Zuge der Sanierung 2019 wurden die Putze und Farbfassungen
              untersucht, deren Ergebnisse in der Führung präsentiert werden.
            </p>
            <p className="newsText">
              Des Weiteren wird die Stadterweiterung von 1860 -1914
              thematisiert, die der Stadt ein neues Gepräge gab.
            </p>
            <a className="newsLink" href={PDF}>
              Zum Flyer
            </a>
          </div>
        </div>
        <div className="newsPageDiv">
          <div className="newsBox">
            <h2 className="newsH2">Kirche in Ammerndorf</h2>
            <h3 className="newsH3 paddingNewsH4">Ammerndorf Kreis Führt</h3>
            <p className="newsText">
              Ev. Pfarrkirche St. Peter und Paul. Der Baubestand deutet auf eine
              ursprüngliche spätmittelalterliche Chorturmanlage hin. Die erste
              urkundliche Erwähnung stammt aus dem Jahr 1256. Am Turm sollen
              1720/30 durch den markgräflichen Regierungsbaumeister Karl
              Friedrich von Zocha Instandsetzungsmaßnahmen vorgenommen worden
              sein. Die Barockisierung des Kirchenraums erfolgte durch Joh.
              David Steingruber 1761.
            </p>
            <p className="newsText">
              Das heutige Kirchenschiff wurde als Saalbau mit einer dreiseitigen
              Empore errichtet. In der ehemaligen Choröffnung nimmt ein
              Kanzelaltar von 1787 den Platz ein, den Joh. Paulus Weimar schuf.
            </p>
            <p className="newsText">
              2020 bis 2021 führt unsere Firma restaura hier eine
              Restaurierungsmaßnahme durch.
            </p>
            <div className="aktuellesBilderSection">
              <label className="aktuellesBildLabel" htmlFor="Ammerndorf2">
                <StaticImage
                  id="Ammerndorf1"
                  src="../images/Ammerndorf1.jpg"
                  alt="Ammerndorf Vorzustand"
                  className="aktuellesBild"
                />
                Vorzustand
              </label>
              <label className="aktuellesBildLabel" htmlFor="Ammerndorf2">
                <StaticImage
                  id="Ammerndorf2"
                  src="../images/Ammerndorf2.jpg"
                  alt="Ammerndorf Endzustand"
                  className="aktuellesBild"
                />
                Endzustand
              </label>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
